<template>
	<div class="identityLike">
		<el-row :gutter="10">
			<el-col :span="24">
				<el-card shadow="hover"><div id="myChart" :style="{ width: '100%', height: '350px' }"></div></el-card>
			</el-col>
			<el-col :span="24">
				<el-card shadow="hover"><div id="myChart2" :style="{ width: '100%', height: '350px' }"></div></el-card>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'identityLike',
	data() {
		this.myChart = null;
		this.myChart2 = null;
		return {
			dataList: [],
			productId: ''
		};
	},
	created() {
		this.productId = this.$TOOL.data.get('DATA_SELECTEED');
		this.listQuery();
	},
	watch: {
		'$store.state.dataSelected'(newval) {
			if (this.productId != newval) {
				this.productId = newval;
				this.listQuery();
			}
		}
	},
	methods: {
		listQuery() {
			this.$HTTP.post(
				'identityLike/query',
				{
					productId: this.productId
				},
				response => {
					if (response.status >= 200 && response.status < 300) {
						if (response.data.code == 0) {
							this.dataList = response.data.data;
							this.drawLine();
						} else {
							this.$message.error(response.data.msg);
						}
					} else {
						console.log(response.message);
					}
				}
			);
		},

		drawLine() {
			// 基于准备好的dom，初始化echarts实例
			this.$echarts.init(document.getElementById('myChart')).dispose();
			this.myChart = this.$echarts.init(document.getElementById('myChart'));
			// 绘制图表
			this.myChart.setOption({
				title: {
					text: '新增用户'
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						label: {
							backgroundColor: '#6a7985'
						}
					}
				},
				legend: {
					data: ['今日喜爱度', '最近喜爱度']
				},
				grid: {
					left: '2%',
					right: '2%',
					bottom: '2%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: false,
						data: this.dataList.newIdentityList
					}
				],
				yAxis: [
					{
						type: 'value'
					}
				],
				series: [
					{
						name: '今日喜爱度',
						type: 'line',
						// stack: 'Total',
						areaStyle: {},
						label: { show: true },
						emphasis: {
							focus: 'series'
						},
						data: this.dataList.newTodayLikesList
					},
					{
						name: '最近喜爱度',
						type: 'line',
						// stack: 'Total',
						areaStyle: {},
						label: { show: true },
						emphasis: {
							focus: 'series'
						},
						data: this.dataList.newRecentLikesList
					}
				]
			});

			// 基于准备好的dom，初始化echarts实例
			this.$echarts.init(document.getElementById('myChart2')).dispose();
			this.myChart2 = this.$echarts.init(document.getElementById('myChart2'));
			// 绘制图表
			this.myChart2.setOption({
				title: {
					text: '日活用户'
				},
				tooltip: {
					trigger: 'axis',
					axisPointer: {
						type: 'cross',
						label: {
							backgroundColor: '#6a7985'
						}
					}
				},
				legend: {
					data: ['日活今日喜爱度', '日活最近喜爱度']
				},
				grid: {
					left: '2%',
					right: '2%',
					bottom: '2%',
					containLabel: true
				},
				xAxis: [
					{
						type: 'category',
						boundaryGap: false,
						data: this.dataList.identityList
					}
				],
				yAxis: [
					{
						type: 'value'
					}
				],
				series: [
					{
						name: '日活今日喜爱度',
						type: 'line',
						// stack: 'Total',
						areaStyle: {},
						label: { show: true },
						emphasis: {
							focus: 'series'
						},
						data: this.dataList.todayLikesList
					},
					{
						name: '日活最近喜爱度',
						type: 'line',
						// stack: 'Total',
						areaStyle: {},
						label: { show: true },
						emphasis: {
							focus: 'series'
						},
						data: this.dataList.recentLikesList
					}
				]
			});
		}
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.identityLike {
	background: #ffffff;
	padding: 15px;
}
</style>
